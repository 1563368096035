/* ng-sortable css file */
.as-sortable-item {
    display: block;
}

.as-sortable-item-handle {
    -ms-touch-action: none;
    touch-action: none;
    -webkit-touch-callout: none;
}

.as-sortable-item-handle {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.as-sortable-drag {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    overflow: hidden;    
    border: 2px dashed rgba(0,0,0,0.5);
    margin: 0;
    padding: 0;
}

.as-sortable-hidden {
    display: none !important;
}

.as-sortable-un-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.as-sortable-dragging .as-sortable-item {
    width: 100%;
    padding: 0;
    height: 100% ! important;
    margin: 0;
    box-sizing: border-box;
}
